import { ThemeProvider } from 'styled-components'
import { FeatureFlaggedApp } from './FeatureFlaggedApp'
import { GlobalStyleHelper as GlobalStyle } from './GlobalStyle'
import { useConfig } from './config/useConfig'
import { WithFeatureFlags } from './feature-flags/WithFeatureFlags'
import { theme } from './theme'

export function InnerApp() {
  const config = useConfig()

  return config ? (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <WithFeatureFlags>
        <FeatureFlaggedApp config={config} />
      </WithFeatureFlags>
    </ThemeProvider>
  ) : null
}
