import { PropsWithChildren } from 'react'
import FlagProvider from '@unleash/proxy-client-react'
import { getUnleashURL } from 'src/urls'

export function WithFeatureFlags({ children }: PropsWithChildren<object>) {
  return (
    <FlagProvider
      config={{
        url: `${getUnleashURL()}/proxy`,
        clientKey: 'public_unleash_client_key',
        appName: 'admin',
      }}
    >
      {children}
    </FlagProvider>
  )
}
