import { useFlagsStatus } from '@unleash/proxy-client-react'
import { t } from 'i18next'
import styled from 'styled-components'
import { ConfiguredApp } from './ConfiguredApp'
import { Config } from './config'
import { TitleH2 } from './elements/TitleH2'

const Loading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`
interface Props {
  config: Config | null
}
export function FeatureFlaggedApp({ config }: Props) {
  const { flagsReady } = useFlagsStatus()

  return flagsReady && config !== null ? (
    <ConfiguredApp config={config} />
  ) : (
    <Loading>
      <TitleH2>{t('screens.loading')}</TitleH2>
    </Loading>
  )
}
