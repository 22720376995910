import { useEffect, useMemo } from 'react'
import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  split,
} from '@apollo/client'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { getMainDefinition } from '@apollo/client/utilities'
import { useFlag } from '@unleash/proxy-client-react'
import { Auth, Hub } from 'aws-amplify'
import { AUTH_TYPE, AuthOptions, createAuthLink } from 'aws-appsync-auth-link'
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link'
import { createClient } from 'graphql-ws'
import { useEvent } from 'react-use'
import { Config } from 'src/config'
import { getApiUrl } from 'src/config/urls'
import { isMocking } from 'src/isMocking'

function createApolloClient(
  url: string,
  oldUrl: string,
  region: string,
  isUsingNewMutationsAndQueries: boolean,
  isUsingNewSubscription: boolean
) {
  const httpLink = new HttpLink({
    uri: isUsingNewMutationsAndQueries ? url : oldUrl,
  })

  const getJwtToken = async () => {
    const session = await Auth.currentSession()
    return session.getAccessToken().getJwtToken()
  }

  const auth: AuthOptions = {
    type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
    jwtToken: getJwtToken,
  }

  const websocketUrl = url
    .replace('https://', 'wss://')
    .replace('http://', 'ws://')

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query)
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      )
    },
    new GraphQLWsLink(
      createClient({
        url: websocketUrl,
        connectionParams: async () => ({
          authentication: await getJwtToken(),
        }),
      })
    ),
    httpLink
  )

  const link = ApolloLink.from([
    createAuthLink({
      url: isUsingNewMutationsAndQueries ? url : oldUrl,
      region,
      auth,
    }),
    isUsingNewSubscription
      ? splitLink
      : createSubscriptionHandshakeLink(
          { url: oldUrl, region, auth },
          httpLink
        ),
  ])

  const client = new ApolloClient({
    cache: new InMemoryCache(),
    connectToDevTools: true,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'cache-and-network',
        notifyOnNetworkStatusChange: true,
        pollInterval: 30000,
        skipPollAttempt: () => document.hidden,
      },
    },
    link,
  })

  return client
}

export function useCreateApolloClient({ oldGraphQLEndpoint, region }: Config) {
  const graphQLEndpoint = getApiUrl()

  const isUsingNewMutationsAndQueries = useFlag(
    'admin-new-graphql-mutations-and-queries-199'
  )
  const isUsingNewSubscription = useFlag('admin-new-graphql-subscriptions-199')

  const client = useMemo(() => {
    const oldGraphQLEndpointUrl = isMocking()
      ? 'https://dummy/graphql'
      : oldGraphQLEndpoint

    const graphQLEndpointUrl = isMocking()
      ? 'https://dummy/graphql'
      : graphQLEndpoint

    return createApolloClient(
      graphQLEndpointUrl,
      oldGraphQLEndpointUrl,
      region,
      isUsingNewMutationsAndQueries,
      isUsingNewSubscription
    )
  }, [
    graphQLEndpoint,
    oldGraphQLEndpoint,
    region,
    isUsingNewMutationsAndQueries,
    isUsingNewSubscription,
  ])

  useEffect(() => {
    return () => {
      client.stop()
    }
  }, [client])

  useEvent('visibilitychange', () => {
    if (document.visibilityState === 'visible') {
      client.refetchQueries({ include: 'active' })
    }
  })

  useEffect(() => {
    return Hub.listen('auth', async ({ payload: { event } }) => {
      if (event === 'signOut') {
        client.clearStore()
      }
    })
  }, [client])

  return client
}
